/*
 * @Author: 冯杰
 * @Date: 2021-10-20 14:59:47
 * @LastEditTime: 2021-10-25 18:06:25
 * @FileName: 消费者中奖列表
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import request from '@/found/utils/request';

export default {
  name: 'ConsumerWinnersList',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/activity/rewardActivityReceive/findByConditions',
      requestType: 'GET',
    };
  },
  components: {
    Modal,
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('consumer_winners_list');
  },
};
